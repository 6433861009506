export const Workers = {
  AccountBankCode: 'Kód banky',
  AccountIban: 'IBAN',
  AccountNumber: 'Číslo účtu',
  Active: 'Aktívny',
  Code: 'Kód',
  Company: 'Spoločnosť',
  CompanyCode: 'Kód spoločnosti',
  CurrentAvailable: 'Akt. ostáva',
  CurrentBudget: 'Akt. nárok',
  CurrentMonth: 'Akt. mesiac',
  CurrentRealized: 'Akt. vybrané',
  CurrentTotalHours: 'Akt. hodín',
  DateLastLogin: 'Posl. prihlásenie',
  DateLastPayment: 'Posl. žiadost',
  DateLastRefresh: 'Posl. aktualizácia',
  DateRegistered: 'Registrácia',
  DismissDate: 'Ukončený prac. pomer',
  InExecution: 'V platobnej neschopnosti',
  InInsolvency: 'V insolvencii',
  FirstName: 'Meno',
  HourSalaryAvg: 'Priemerná hod. mzda',
  Idwa: 'IDWA',
  Idws: 'IDWS',
  LastName: 'Priezvisko',
  MonthSalary: 'Mesačný plat',
  PayLimitPercentage: 'Limit %',
  ResetPin: 'Resetovať PIN',
  ResetPinConfirmText:
    'Naozaj chcete resetovať PIN, aby si ho zamestnanec zadať znovu pri prihlásení?',
  ResetPinSuccess: 'PIN resetovaný',
  WageDeduction: 'Zrážky zo mzdy',
  Worker: 'Pracovník',
  WorkingTime: 'Prac. úväzok v hodinách/týždeň',
  ReferralCode: 'Referal kód',
  RefreshWorker: 'Aktualizácia pracovníka',
  RefreshWorkerConfirmText:
    'Naozaj chcete aktualizovať údaje od zamestnávateľa (údaje, dochádzka)?',
  RefreshWorkerSuccess: 'Aktualizácia vykonaná',
  ExcludedPeriod: 'Vylúčené obdobie',
  ExcludedPeriodText:
    'Vylúčené obdobie je obdobie, počas ktorého sa pre používateľa nepočíta žiadna mzda.',
  ExcludedPeriodFrom: 'od (vtrátane)',
  ExcludedPeriodTo: 'do (vtrátane)',
  DownloadAgreement: 'Stiahnuť Dohodu o zrážkach zo mzdy',
  DownloadAgreementText: 'Prajete si stiahnuť PDF súbor?',
  EnableWorker: 'Aktivovať pracovníka',
  EnableWorkerConfirmText: 'Ste si istí, že chcete pracovníka aktivovať?',
  EnableWorkerSuccess: 'Pracovník aktivovaný',
  DisableWorker: 'Deaktivovať pracovníka',
  DisableWorkerConfirmText: 'Ste si istí, že chcete pracovníka deaktivovať?',
  DisableWorkerSuccess: 'Pracovník deaktivovaný',
  State: 'Stav',
  Email: 'Email',
  Phone: 'Telefón',
};

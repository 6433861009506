import { Typography } from '@mui/material';
import Paid from '@/assets/images/paid.jpg';

export const AuthorizationSuccess = () => {
  return (
    <>
      <Typography align='center' variant='h3'>
        SUKCES!!!
      </Typography>
      <img src={Paid} />
    </>
  );
};

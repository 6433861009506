import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { flatten } from 'lodash';
import { noAuthRoutes, useRoutes, userMenuRoutes } from './items';
import { Route as RoutePath } from '@/enums';
import { useAppContext } from '@/context';
import { RouteItem } from './types';
import { AuthorizationSuccess } from '@/pages/BankAuthorizations/AuthorizationSuccess';

export const Routes = () => {
  const routes = useRoutes();
  const { loggedIn } = useAppContext();

  return (
    <RouterRoutes>
      {(loggedIn
        ? [...flatten(routes.map(({ items, ...rest }) => items ?? rest)), ...userMenuRoutes]
        : noAuthRoutes
      )
        .filter((item) => 'page' in item && item.page && 'path' in item && item.path)
        .map(mapRoute)}
      <Route path='authorizationsuccess' element={<AuthorizationSuccess />} />
      <Route
        path='*'
        element={<Navigate to={loggedIn ? RoutePath.Dashboard : RoutePath.Login} replace />}
      />
    </RouterRoutes>
  );
};

const mapRoute = ({ path, page: module }: RouteItem) => {
  const Module = module as () => JSX.Element;

  return <Route key={path} path={path} element={<Module />} />;
};

// @ts-nocheck
// tslint:disable
import axios, { AxiosRequestConfig, AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, Canceler } from "axios";
// Utils
import { isEmpty, StorageManagement } from "@sebgroup/frontend-tools";


let mocks: any;

if (process.env.NODE_ENV !== "production") {
    mocks = require("./mock.json");
}

interface KeyValuePair {
    [key: string]: any;
}

export interface DefaultConfig {
    delay: number;
    defaultHeaders?: () => {};
    useMock?: boolean;
}

export const defaultBasePath: string = "https://devel.service.palmapp.com".replace(/\/$/, "");

const configs: DefaultConfig = {
    delay: 1500,
    defaultHeaders: () => {
        return {};
    },
    useMock: false
};

export enum StorageKeysEnum {
    TOKEN = "TOKEN"
}

export interface AxiosResponseInterceptor<T = any> {
    onFullfilled: (response: AxiosResponse<T>) => AxiosResponse<T>;
    onRejected: (err: AxiosError<any>) => Promise<never>;
}

export interface AxiosRequestInterceptor {
    onFullfilled: (axiosConfig: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
    onRejected: (err: any) => number;
}

export interface ApiInterceptorsProps {
    request: AxiosRequestInterceptor;
    response: AxiosResponseInterceptor;
}

export function simulateData(resolve: any, data: any, status: number, timeout: number): any {
    return setTimeout(() => { resolve({ status: status, data: data }); }, timeout);
}

export class APIHandler {
    protected basePath = defaultBasePath;
    protected axiosInstance: AxiosInstance = axios.create();
    protected storage: StorageManagement = new StorageManagement();
    protected className: string;

    constructor(className: string) {
        this.className = className;
        
    }

    /**
     * Initializes the API url with the URL params
     * @param path The API path
     * @param urlParams The url params to be inserted in the API path
     * @returns The initialized API URL
     */
    protected initAPIURL(path: string, urlParams: KeyValuePair): string {
        if (urlParams) {
            for (let key in urlParams) {
                path = path.replace(`{${key}}`, urlParams[key]);
            }
        }
        return this.basePath + path;
    }

    /**
     * Merges two objects by mutating the original with the add-on
     * @param original The original object that will be mutated with the add on object
     * @param addOn The add-on object to be merged with the original
     */
    protected mergeObjects(original: any, addOn: any): void {
        if (!isEmpty(addOn)) {
            for (let key in addOn) {
                if (addOn[key] !== undefined) {
                    original[key] = addOn[key];
                }
            }
        }
    }

    /**
     * Initializes the API call options with query params and header params
     * @param options The HTTP options object to be initialized
     * @param queryParams The query params to be inserted
     * @param headerParams The header params to be inserted
     * @param isAuth Whether to apply token for authentication or not
     * @returns the initialized options object
     */
    protected initOptions(options: AxiosRequestConfig, queryParams: KeyValuePair, headerParams: KeyValuePair, canceler: (c: Canceler) => void): AxiosRequestConfig {
        const defaultHeaders: any = configs.defaultHeaders();
        options = options || {};
        options.headers = !isEmpty(defaultHeaders) ? { ...defaultHeaders, ...options.headers } : options.headers;
        options.params = options.params ? options.params : {};
        options.responseType = options.responseType ? options.responseType : "json";
        this.mergeObjects(options.params, queryParams);
        this.mergeObjects(options.headers, headerParams);
        if (canceler) {
            options.cancelToken = new axios.CancelToken((cancel: Canceler) => canceler(cancel))
        }
        return options;
    }

    /**
     * Make an API call
     * @param method The HTTP method (`get`, `delete`, `post`, `put`)
     * @param url The URL of the API to call
     * @param options The http options to be sent
     * @param body The body of the request, if any
     * @param methodName The name of the api method making this call
     */
    protected makeCall<T>(method: string, url: string, operationId: string, options: AxiosRequestConfig, body: any = null, methodName: string): AxiosPromise<T> {
        if (configs.useMock) {
            let mockedData: any = {};
            try {
                mockedData = mocks[operationId][200] || mocks[operationId]["default"] || mocks[operationId];
            } catch (error) {
                console.error(error);
            }
            return new Promise((resolve) => { simulateData(resolve, mockedData, 200, configs.delay); });
        } else {
            switch (method) {
                case "get": return this.axiosInstance.get<T>(url, options);
                case "delete": return this.axiosInstance.delete(url, options);
                case "post": return this.axiosInstance.post<T>(url, body, options);
                case "put": return this.axiosInstance.put<T>(url, body, options);
                default: throw new Error(`Invalid HTTP method ${method}`);
            }
        }
    }
}

export type AttributeMapItem = {
    name: string;
    type: string;
};

export type APIParams = {
    canceler?: (c: Canceler) => void;
    options?: AxiosRequestConfig;
};

export type APIModelOptions = {
    [key: string]: any;
    /** The value of properties of type `string` */
    string?: string;
    /** The value of properties of type `number` */
    number?: number;
    /** The value of  properties of type`boolean` */
    boolean?: boolean;
    /** The value  properties of typeof `date` */
    date?: Date;
    /** The value o properties of typef `array` */
    array?: Array<any>;
};

export class APIModel {

    /**
     * Initializes and attribute map item as a primitive type
     * @param item An attribute map item to use as reference
     * @param options The options passed in the constructor to override the default values
     * @returns The value to be initialized with
     */
    static initAsPimitiveType(item: AttributeMapItem, options?: APIModelOptions): any {
        switch (true) {
            case /string/i.test(item.type): return options && options.string !== undefined ? options.string : "";
            case /number/i.test(item.type): return options && options.number !== undefined ? options.number : null;
            case /boolean/i.test(item.type): return options && options.boolean !== undefined ? options.boolean : null;
            case /date/i.test(item.type): return options && options.date !== undefined ? options.date : null;
            case /array/i.test(item.type): return options && options.array !== undefined ? options.array : [];
            default: return null;
        }
    }

    constructor(attributeMap: Array<AttributeMapItem>, options?: APIModelOptions) {
        if (attributeMap && attributeMap.map) {
            attributeMap.map((item: AttributeMapItem) => {
                if (options && options instanceof Object) {
                    const optionName: string = Object.keys(options).find((name: string) => {
                        return item.name.toLowerCase() === name.toLowerCase();
                    });
                    if (optionName) {
                        this[item.name] = options[optionName];
                    } else {
                        this[item.name] = APIModel.initAsPimitiveType(item, options);
                    }
                } else {
                    this[item.name] = APIModel.initAsPimitiveType(item, options);
                }
            });
        }
    }
}

export namespace APIURLTypes {
    export interface AuthApi {
        apiV1AdminAuthLoginPost: string;
        apiV1AdminAuthLogoutPut: string;
        apiV1AdminAuthPasswordPut: string;
        apiV1ClientAuthActiveThemeGet: string;
        apiV1ClientAuthCreateUsernameAndPasswordOtpPost: string;
        apiV1ClientAuthCreateUsernameAndPasswordPost: string;
        apiV1ClientAuthLoginPost: string;
        apiV1ClientAuthLogoutPut: string;
        apiV1ClientAuthOverviewGet: string;
        apiV1ClientAuthSalaryDeductionAgreementGet: string;
    }
    export interface AuthenticationApi {
        apiV1ClientAuthenticationLoginPost: string;
        apiV1ClientAuthenticationLogoutPut: string;
    }
    export interface BalancesApi {
        apiV1AdminBalancesGet: string;
        apiV1AdminBalancesIdErpGet: string;
        apiV1AdminBalancesIdGet: string;
        apiV1AdminBalancesIdXlsxPaymentsGet: string;
        apiV1AdminBalancesXlsxGet: string;
    }
    export interface CardTransactionsApi {
        apiV1AdminCardTransactionsAddRefundPost: string;
        apiV1AdminCardTransactionsGet: string;
    }
    export interface CardsApi {
        apiV1AdminCardsGet: string;
        apiV1ClientCardsPost: string;
    }
    export interface CashbacksApi {
        apiV1AdminCashbacksGeneratePayoutsPost: string;
    }
    export interface CeskaSporitelnaApi {
        apiV1AdminCeskaSporitelnaAuthenticationUrlGet: string;
        apiV1AdminCeskaSporitelnaAuthorizationLinkPost: string;
        apiV1AdminCeskaSporitelnaBatchPost: string;
        apiV1AdminCeskaSporitelnaBatchStateBatchIdGet: string;
        apiV1AdminCeskaSporitelnaCompleteAuthenticationPost: string;
        apiV1AdminCeskaSporitelnaDirectDebitPost: string;
        apiV1AdminCeskaSporitelnaPaymentBatchesGet: string;
        apiV1AdminCeskaSporitelnaPaymentStatePaymentIdGet: string;
        apiV1AdminCeskaSporitelnaPollBatchesPost: string;
        apiV1AdminCeskaSporitelnaVerifyConnectionGet: string;
    }
    export interface CompaniesApi {
        apiV1AdminCompaniesBalancesPost: string;
        apiV1AdminCompaniesDirectDebitCardsPost: string;
        apiV1AdminCompaniesDirectDebitPost: string;
        apiV1AdminCompaniesGet: string;
        apiV1AdminCompaniesIdCurrentBudgetGet: string;
        apiV1AdminCompaniesIdCurrentWorkersGet: string;
        apiV1AdminCompaniesIdDelete: string;
        apiV1AdminCompaniesIdGet: string;
        apiV1AdminCompaniesIdMonthOverviewMonthGet: string;
        apiV1AdminCompaniesIdPut: string;
        apiV1AdminCompaniesIdReferralEnrollPost: string;
        apiV1AdminCompaniesIdReferralRemovePost: string;
        apiV1AdminCompaniesLookupsGet: string;
        apiV1AdminCompaniesPost: string;
        apiV1AdminCompaniesRefreshDemoDataPost: string;
        apiV1AdminCompaniesRefreshPut: string;
        apiV1AdminCompaniesSalaryDeductionAgreementIdGet: string;
        apiV1AdminCompaniesXlsxGet: string;
        apiV1ClientCompaniesIdActiveThemeGet: string;
    }
    export interface CompanyThemesApi {
        apiV1AdminCompanyThemesGet: string;
        apiV1AdminCompanyThemesIdGet: string;
        apiV1AdminCompanyThemesIdPut: string;
        apiV1AdminCompanyThemesPost: string;
        apiV1AdminCompanyThemesXlsxGet: string;
    }
    export interface ConnectorDiagnosticsApi {
        apiV1AdminConnectorDiagnosticsNewWorkerDetailsPost: string;
        apiV1AdminConnectorDiagnosticsWorkerDetailsPost: string;
        apiV1AdminConnectorDiagnosticsWorkerDumpPost: string;
    }
    export interface ConnectorsApi {
        apiV1AdminConnectorsGet: string;
        apiV1AdminConnectorsIdGet: string;
        apiV1AdminConnectorsIdPut: string;
        apiV1AdminConnectorsPost: string;
        apiV1AdminConnectorsXlsxGet: string;
    }
    export interface DirectDebitsApi {
        apiV1AdminDirectDebitsGet: string;
        apiV1AdminDirectDebitsIdGet: string;
        apiV1AdminDirectDebitsIdPerformPost: string;
        apiV1AdminDirectDebitsIdRequestPost: string;
        apiV1AdminDirectDebitsIdXlsxPaymentsGet: string;
        apiV1AdminDirectDebitsRequestSkPost: string;
    }
    export interface ImagesApi {
        apiV1AdminImagesGet: string;
        apiV1AdminImagesIdDelete: string;
        apiV1AdminImagesIdGet: string;
        apiV1AdminImagesIdPut: string;
        apiV1AdminImagesIdUsedGet: string;
        apiV1AdminImagesPost: string;
        apiV1AdminImagesXlsxGet: string;
        apiV1ClientImagesIdGet: string;
    }
    export interface MessagesApi {
        apiV1AdminMessagesAnswersGet: string;
        apiV1AdminMessagesAnswersXlsxGet: string;
        apiV1AdminMessagesBulkPost: string;
        apiV1AdminMessagesGet: string;
        apiV1AdminMessagesIdGet: string;
        apiV1AdminMessagesIdPut: string;
        apiV1AdminMessagesPost: string;
        apiV1AdminMessagesXlsxGet: string;
    }
    export interface OpenAiApi {
        apiV1SharedOpenAiAskPost: string;
        apiV1SharedOpenAiAskStreamPost: string;
    }
    export interface PaymentsApi {
        apiV1AdminPaymentsCzechRepublicBatchExportGet: string;
        apiV1AdminPaymentsGet: string;
        apiV1AdminPaymentsIdGet: string;
        apiV1AdminPaymentsIdProcessPut: string;
        apiV1AdminPaymentsIdVerifyPut: string;
        apiV1AdminPaymentsNotifyUnprocessedPut: string;
        apiV1AdminPaymentsReconcilliatePaymentsPost: string;
        apiV1AdminPaymentsSlovakiaBatchExportGet: string;
        apiV1AdminPaymentsVerifiedRegionIdGet: string;
        apiV1AdminPaymentsXlsxGet: string;
        apiV1ClientPaymentsGet: string;
        apiV1ClientPaymentsIdStateGet: string;
        apiV1ClientPaymentsPost: string;
    }
    export interface RegionsApi {
        apiV1AdminRegionsGet: string;
        apiV1AdminRegionsIdGet: string;
        apiV1AdminRegionsLookupsGet: string;
        apiV1AdminRegionsXlsxGet: string;
    }
    export interface RegistrationApi {
        apiV1ClientRegistrationPushNotificationsAndroidPost: string;
        apiV1ClientRegistrationPushNotificationsApplePost: string;
        apiV1ClientRegistrationRegistrationTokenRequestEmailOtpPost: string;
        apiV1ClientRegistrationRegistrationTokenStep2ControlQuestionPost: string;
        apiV1ClientRegistrationRegistrationTokenStep2EmailOtpPost: string;
        apiV1ClientRegistrationRegistrationTokenStep3Post: string;
        apiV1ClientRegistrationRegistrationTokenStep4Post: string;
        apiV1ClientRegistrationStep1Post: string;
    }
    export interface RegistrationsApi {
        apiV1ClientRegistrationsIdQuestionVerificationOneTimeCodePut: string;
        apiV1ClientRegistrationsIdQuestionVerificationPut: string;
        apiV1ClientRegistrationsPost: string;
        apiV1ClientRegistrationsRegistrationIdRequestEmailCodePost: string;
    }
    export interface RolesApi {
        apiV1AdminRolesGet: string;
    }
    export interface SettingApi {
        apiV1AdminSettingGet: string;
        apiV1AdminSettingPut: string;
    }
    export interface UserApi {
        apiV1ClientUserCardsGet: string;
        apiV1ClientUserChangeCashbackAccountPut: string;
        apiV1ClientUserChangePasswordPut: string;
        apiV1ClientUserKycGet: string;
        apiV1ClientUserOverviewGet: string;
        apiV1ClientUserResetPasswordPut: string;
        apiV1ClientUserSalaryDeductionAgreementGet: string;
        apiV1ClientUserTransactionHistoryGet: string;
    }
    export interface UsersApi {
        apiV1AdminUsersAdminGet: string;
        apiV1AdminUsersAdminXlsxGet: string;
        apiV1AdminUsersIdDelete: string;
        apiV1AdminUsersIdGet: string;
        apiV1AdminUsersIdPut: string;
        apiV1AdminUsersOtherGet: string;
        apiV1AdminUsersOtherXlsxGet: string;
        apiV1AdminUsersPost: string;
    }
    export interface Version1MigrationApi {
        apiLoginPinPost: string;
        apiRegistrationStep1Post: string;
    }
    export interface WorkerMessagesApi {
        apiV1ClientWorkerMessagesGet: string;
        apiV1ClientWorkerMessagesIdGet: string;
        apiV1ClientWorkerMessagesIdPut: string;
    }
    export interface WorkersApi {
        apiV1AdminWorkersGet: string;
        apiV1AdminWorkersIdDisablePut: string;
        apiV1AdminWorkersIdEnablePut: string;
        apiV1AdminWorkersIdGet: string;
        apiV1AdminWorkersIdPinResetPost: string;
        apiV1AdminWorkersIdPut: string;
        apiV1AdminWorkersIdRefreshPut: string;
        apiV1AdminWorkersIdSalaryDeductionAgreementGet: string;
        apiV1AdminWorkersXlsxGet: string;
    }
}

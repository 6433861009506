// @ts-nocheck
// tslint:disable
/**
 * PalmApp Server Web API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



// tslint:disable
import { APIModel, APIModelOptions, AttributeMapItem } from "../base";

export class WorkerDetailDto extends APIModel {
    /**
    * Worker identifier
    */
    id: number;
    /**
    * Idws identifier
    */
    idws: string;
    /**
    * Idwa identifier
    */
    idwa: string;
    /**
    * First name of worker
    */
    firstName: string;
    /**
    * Last name of worker
    */
    lastName: string;
    /**
    * Worker login
    */
    username: string;
    /**
    * Worker email
    */
    email: string;
    /**
    * Worker phone
    */
    phone: string;
    /**
    * Account number
    */
    accountNumber: string;
    /**
    * Account bank code
    */
    accountBankCode: string;
    /**
    * Account IBAN
    */
    accountIban: string;
    /**
    * Worker code
    */
    code: string;
    /**
    * Worker referral code
    */
    referralCode: string;
    /**
    * Currency
    */
    currency: string;
    /**
    * Average salary per hour
    */
    hourSalaryAvg: number;
    /**
    * Wage deduction
    */
    wageDeduction: number;
    /**
    * Working time
    */
    workingTime: number;
    /**
    * Month salary
    */
    monthSalary: number;
    /**
    * Maximum percentage available for withdrawal
    */
    payLimitPercentage: number;
    /**
    * Worker is in execution
    */
    inExecution: boolean;
    /**
    * Worker is in insolvency
    */
    inInsolvency: boolean;
    /**
    * Worker is active
    */
    active: boolean;
    /**
    * Dismiss date
    */
    dismissDate: string;
    /**
    * Excluded period from
    */
    excludedPeriodFrom: string;
    /**
    * Excluded period to
    */
    excludedPeriodTo: string;

    constructor(options?: APIModelOptions) {
        super(WorkerDetailDto.attributeTypeMap, options);
    }

    static get attributeTypeMap(): Array<AttributeMapItem> {
        return [
            { name: "id", type: "number" },
            { name: "idws", type: "string" },
            { name: "idwa", type: "string" },
            { name: "firstName", type: "string" },
            { name: "lastName", type: "string" },
            { name: "username", type: "string" },
            { name: "email", type: "string" },
            { name: "phone", type: "string" },
            { name: "accountNumber", type: "string" },
            { name: "accountBankCode", type: "string" },
            { name: "accountIban", type: "string" },
            { name: "code", type: "string" },
            { name: "referralCode", type: "string" },
            { name: "currency", type: "string" },
            { name: "hourSalaryAvg", type: "number" },
            { name: "wageDeduction", type: "number" },
            { name: "workingTime", type: "number" },
            { name: "monthSalary", type: "number" },
            { name: "payLimitPercentage", type: "number" },
            { name: "inExecution", type: "boolean" },
            { name: "inInsolvency", type: "boolean" },
            { name: "active", type: "boolean" },
            { name: "dismissDate", type: "string" },
            { name: "excludedPeriodFrom", type: "string" },
            { name: "excludedPeriodTo", type: "string" }
        ];
    }
}




